<template>
<NuxtLink :to="`/blog/${post?.slug}`">
    <div class="blog-post-tile">
        <div
            class="blog-post-img"
            aria-hidden
            :style="[{
                'background-image':`
                url('${ featuredImgUrl }')`}]"
        />
        <div class="blog-title">{{ post?.title }}</div>
        <div class="blog-publish-date">{{ dateString }}</div>
    </div>
</NuxtLink>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    props: {
        post: {
            type: Object as PropType<JBGWeb.BlogPost>
        }
    },
    computed: {
        dateString() {
            if (!this.post) return ''
            const d = new Date(this.post?.publishedAt)
            return d.toLocaleDateString(this.$i18n.locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
        featuredImgUrl() {
            if (this.post?.featuredImage) {
                const attributes = this.post?.featuredImage.data.attributes
                if (attributes) {
                    const formats = attributes.formats
                    if (formats?.small?.url) {
                        return `${formats.small.url}?format=auto`
                    } if (formats?.medium?.url) {
                        return `${formats?.medium.url}?format=auto`
                    }
                }
                return `${attributes.url}?format=auto`
            }
            return '/images/placeholders/blog-placeholder.jpg'
        }
    }
})

</script>

<style lang="scss" scoped>

.blog-post-tile {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;

    .blog-post-img {
        display: flex;
        border-radius: 12px;
        transition-duration: 0.2s;
        aspect-ratio: 1.78;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &:hover {
            transform: scale(1.04, 1.04);
            outline: solid 3px var(--primary-50);
            outline-offset: -1.5px;
        }
    }
    .blog-title {
        font-size: 14px;
        color: var(--neutral-50);
        font-weight: 600;
        line-height: 19.6px;
    }

    .blog-publish-date {
        color: var(--neutral-50);
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
    }
}
</style>
